import React, { memo } from 'react';
import { EmployeeComponent, LandingTopAnimated, LandingTechnology } from '@containers';
import { SEO } from '@components';
import { backendTechnologies, frontendTechnologies, mobileTechnologies } from '@content';

import './about.scss';

const AboutPage = () => (
  <div>
    <SEO title="About" />

    <div className="on-about-container">
      <div className="on-about-text">
        <LandingTopAnimated
          title="About us"
          text="ooden is a team of expert developers specialized in application and service development.
      We're based in Zagreb, Croatia, but we work with companies all over the world."
        />
        <h1>Our journey</h1>
        <p>
        </p>
      </div>
    </div>
    <LandingTechnology text="Backend" items={backendTechnologies} />
    <LandingTechnology text="Frontend" items={frontendTechnologies} />
    <LandingTechnology text="Mobile" items={mobileTechnologies} />
    {/*<EmployeeComponent title="Our family" />*/}
    <div className="on-about-padding" />
  </div>
);

export default memo(AboutPage);
